
import { Vue, Component } from 'vue-property-decorator'
import Summary from './components/summary.vue'
import ProjectFolder from './components/project-folder.vue'
import ProjectApi from '@/api/project/project-api'
import { PageListData, PagerQueryBean } from '@/libs/http-request'
import { Project } from '@/api/project/project'
import _ from 'lodash'
import { ProjectGroup } from '@/api/project-group/project-group'
import { getterUserInfo } from '@/store'
import moment from 'moment'

const defaultQuery = {
  page: 1,
  pageSize: 10,
  sorts: ['-createTime'],
  nameAtTeam: undefined,
  userPhone: undefined,
  dateRage: undefined
}

@Component({
  name: 'SubProject',
  components: { Summary, ProjectFolder }
})
export default class SubProject extends Vue {
  isShowSpin = false;

  /// 查询数据
  queryData: PagerQueryBean = _.cloneDeep(defaultQuery);

  curProjectGroup: ProjectGroup = {
    id: 0,
    name: '未知'
  };

  pageData: PageListData<Project> = {
    items: [],
    total: 0,
    pageSize: 10000
  };

  created () {
    this.curProjectGroup = {
      id: parseInt(this.$route.query.projectGroupId as string),
      name: this.$route.query.projectGroupName as string
    }
  }

  mounted () {
    this.loadDataFromServer()
  }

  onAddNewProject () {
    this.loadDataFromServer()
  }

  onDeleteProject () {
    this.loadDataFromServer()
  }

  /** 是否开通项目模块 */
  get isOpeningProjectModule () {
    const user = getterUserInfo()
    const openWebApi = user.webApiExpirationTime && moment(user.webApiExpirationTime).isAfter(moment.now())
    const openWebFront = user.webFrontExpirationTime && moment(user.webFrontExpirationTime).isAfter(moment.now())
    return openWebApi || openWebFront
  }

  loadDataFromServer (): Promise<any> {
    this.isShowSpin = true
    return ProjectApi.allItemsByProjectGroupId(this.curProjectGroup.id!)
      .then(resp => {
        const pageData = resp.data!
        this.pageData.items = pageData.items.filter(item => !item.deleted)
      })
      .finally(() => {
        this.isShowSpin = false
      })
  }
}
