/**
 * 权限配置模块
 */
import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult } from '@/libs/http-request'
import { ModuleItem } from '@/libs/rights-define-manger'

/**
  * 权限配置请求api接口
  */
class ModulerightEditApi extends DefaultApiService<ModuleItem> {
  /**
    * 上传权限模型数据
    * @param projectId 项目Id
    * @param versionId 版本Id
    * @returns
    */
  upLoadRightModelData (projectId: number, versionId: number, data: any): Promise<DataApiResult<ModuleItem>> {
    data = { data: data }
    return request({ url: `${this.baseUrlPath}upLoadRightModelData/${projectId}/${versionId}`, data, method: 'POST' }, true, true)
  }
}

export default new ModulerightEditApi('/webapi/right-module-manger')
