/**
 * 权限配置模块
 */
import DefaultApiService from '@/libs/api-request'
import request, { DataApiResult } from '@/libs/http-request'
import { ModuleItem } from '@/libs/rights-define-manger'

/**
 * 模块项定义
 */
export interface CrossData {
    /** 模块编码,3位一段 */
    name: string;
    /** 模块的标题 */
    title: string;
    /** 模块的名称 */
    type: string;
    /** 模块的路由路径 */
    subType: string;
    /** 模块icon名称，对应fonticon */
    saveMode: string;
    /** 是否是菜单项 */
    showOnMenu: boolean;
    /** uuid */
    id: number;
  }

/**
   * 权限配置请求api接口
   */
class CrossDataEditApi extends DefaultApiService<CrossData> {
  /**
     * 上传权限模型数据
     * @param projectId 项目Id
     * @param versionId 版本Id
     * @returns
     */
  upLoadRightModelData (projectId: number, version: string, uuid: string, data: any): Promise<DataApiResult<ModuleItem>> {
    data = { data: data }
    return request({ url: `${this.baseUrlPath}upLoadModelData/${projectId}/${version}/${uuid}`, data, method: 'POST' }, true, true)
  }
}

export default new CrossDataEditApi('/webapi/cross-data')
