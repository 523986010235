/**
 * 实体版本配置模块
 */

import DefaultApiService from '@/libs/api-request'
import { ProjectVersion } from './project-version'

/**
 * 实体版本服务请求api接口
 */
class ProjectVersionApi extends DefaultApiService<ProjectVersion> {
  /**
   * 根据项目id取得所有的版本信息
   * @param projectId
   * @returns
   */
  getVersionListByProjectId (projectId: number) {
    return this.requestList(
            `getVersionListByProjectId/${projectId}`, null, 'GET'
    )
  }

  /**
   * 根据原版本id创建新版本
   * @param projectId 项目id
   * @param sourceVersionId 原版本id
   * @param newVersionTitle 新版本标题
   * @returns
   */
  createNewVersion (projectId: number, sourceVersionId: number, newVersionTitle: string) {
    return this.request(
      'create-new-version',
      {
        sourceVersionId,
        projectId,
        newVersionTitle
      }
    )
  }
}

export default new ProjectVersionApi('/frontapi/projectVersion')
