
import {
  Project,
  ProjectTypeWebApi,
  ProjectTypeWebFront,
  ProjectTypeFlutterApp
} from '@/api/project/project'
import ProjectApi from '@/api/project/project-api'
import { Prop, Vue, Component } from 'vue-property-decorator'
import { getterUserInfo } from '@/store'
import security from '@/api/security'

/// webapi工程子系统的url地址
const projectWebApiUrl = process.env.VUE_APP_ProjectUrl_WebApi
/// webfront项目系统的地址
const projectWebFrontUrl = process.env.VUE_APP_ProjectUrl_WebFront
/// flutterApp项目系统的地址
const projectFlutterAppUrl = process.env.Flutter_APP_ProjectUrl

@Component({
  name: 'ProjectFolder',
  components: {}
})
export default class ProjectFolder extends Vue {
  // 鼠标状态 进入enter 离开leave
  mouseState = 'leave'

  get folderImageSrc () {
    if (!this.folder) return undefined
    if (this.folder.type === ProjectTypeWebApi) {
      return require('@/assets/project/project-type-webapi.png')
    }
    if (this.folder.type === ProjectTypeWebFront) {
      return require('@/assets/project/project-type-webapp.png')
    }
    if (this.folder.type === ProjectTypeFlutterApp) {
      return require('@/assets/project/project-type-app.png')
    }
  }

  @Prop({
    type: String,
    default: '未知'
  })
  folderName?: string

  @Prop({
    type: Object
  })
  folder!: Project

  /* @Prop({
    type: Boolean,
    default: false
  })
  canEdit?: boolean; */

  get editIconSrc () {
    return this.canEdit
      ? require('@/assets/project/folder-edit-actived.png')
      : require('@/assets/project/folder-edit-disabled.png')
  }

  get deleteIconSrc () {
    return this.canDelete
      ? require('@/assets/project/folder-delete-actived.png')
      : require('@/assets/project/folder-delete-disabled.png')
  }

  // 打开项目界面 project-webapi
  openProject () {
    if (this.folder.type === ProjectTypeWebApi) {
      security.requestOssUrl(projectWebApiUrl + '#/?projectId=' + this.folder.id)
        .then((url) => {
          window.open(url, '_self')
        })
        .catch((err) => {
          console.log('打开项目失败 %o', err)
          this.$Message.error('打开项目失败:' + err.message)
        })
    }
    if (this.folder.type === ProjectTypeWebFront) {
      security.requestOssUrl(projectWebFrontUrl + '#/home?projectId=' + this.folder.id)
        .then(url => window.open(url, '_self'))
        .catch(err => {
          this.$Message.error('打开前端页面失败:' + err.message)
        })
    }
    if (this.folder.type === ProjectTypeFlutterApp) {
      security.requestOssUrl(projectFlutterAppUrl + '#/?projectId=' + this.folder.id)
        .then(url => window.open(url, '_self'))
        .catch(err => {
          this.$Message.error('打开前端页面失败:' + err.message)
        })
    }
  }

  onMouseEnter () {
    this.mouseState = 'enter'
  }

  onMouseLeave () {
    this.mouseState = 'leave'
  }

  onClickDelete () {
    if (!this.folder) {
      return
    }
    this.$Modal.confirm({
      title: '是否删除？',
      content: `是否删除${this.folder.title}？`,
      onOk: () => {
        ProjectApi.deleteItemByIds([this.folder!.id!])
          .then(resp => {
            this.$Message.info('操作成功')
            this.$emit('on-delete-project')
          })
          .catch(err => {
            this.$Message.error({
              content: err.message
            })
          })
      }
    })
  }

  onClickEdit () {
    if (this.folder.type === ProjectTypeWebApi) {
      security.requestOssUrl(projectWebApiUrl + '#/project-webapi/base-info-page?projectId=' + this.folder.id)
        .then((url) => {
          window.open(url, '_self')
        })
        .catch((err) => {
          console.log('打开项目失败 %o', err)
          this.$Message.error('打开项目失败:' + err.message)
        })
    }
    if (this.folder.type === ProjectTypeWebFront) {
      security.requestOssUrl(projectWebFrontUrl + '#/projectForm?projectId=' + this.folder.id)
        .then(url => window.open(url, '_self'))
        .catch(err => {
          this.$Message.error('打开前端页面失败:' + err.message)
        })
    }
    if (this.folder.type === ProjectTypeFlutterApp) {
      security.requestOssUrl(projectFlutterAppUrl + '#/?projectId=' + this.folder.id)
        .then(url => window.open(url, '_self'))
        .catch(err => {
          this.$Message.error('打开前端页面失败:' + err.message)
        })
    }
  }

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canCreateProject
  }

  /**
   * 当前用户的项目删除权限
   */
  get canDelete () {
    const user = getterUserInfo()
    return user.canDeleteProject
  }
}
