
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import Modals from '@/views/project/my-project/sub-project/data-list/modal/modals'
import ProjectApi from '@/api/project/project-api'
import { ProjectGroup } from '@/api/project-group/project-group'
import { getterUserInfo } from '@/store'
import ModulerightEditApi from '@/api/project/moduleright-edit-api'
import RightItemDefineManger from '@/libs/rights-define-manger'
import ProjectVersionApi from '@/api/project-version/project-version-api'
import CrossDataEditApi from '@/api/project/cross-data-api'
import moment from 'moment'

@Component({
  name: 'Summary',
  components: { }
})
export default class Summary extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        id: 0,
        name: '未知'
      }
    }
  })
  projectGroup!: ProjectGroup

  rightItemDefineManger: RightItemDefineManger = new RightItemDefineManger()

  onClickAddNewProjectButton () {
    const that = this
    Modals.showProjectCreatorModal(
      {
        type: 1,
        projectGroupId: this.projectGroup.id
      },
      ({ type, orgName, title, name, options }) => {
        return new Promise<void>((resolve, reject) => {
          if (type === 1) {
            ProjectApi
              .addWebApiProject(orgName, name!, title!, this.projectGroup.id!, options)
              .then(async res => {
                this.$Message.success('操作成功')

                // 给项目新增空的权限文件
                const projectVersion = await ProjectVersionApi.getVersionListByProjectId(res.data!.id!)
                await ModulerightEditApi.upLoadRightModelData(res.data?.id!, projectVersion.data![0].id!, this.rightItemDefineManger.data)
                that.$emit('on-add-new-project')
                resolve()
              })
              .catch(err => {
                this.$Message.error({
                  content: err.message
                })
              })
          } else if (type === 2) {
            ProjectApi
              .addWebFrontProject(name, title, this.projectGroup.id!)
              .then(async (res) => {
                this.$Message.success('操作成功')
                // 给项目新增空的权限文件
                const projectVersion = await ProjectVersionApi.getVersionListByProjectId(res.data!.id!)
                CrossDataEditApi.upLoadRightModelData(res.data?.id!, projectVersion.data![0].uuid!, 'acrossData', {
                  projectId: res.data?.id,
                  projectName: res.data?.name,
                  children: []
                })
                this.$emit('on-add-new-project')
                resolve()
              })
              .catch(err => {
                this.$Message.error({
                  content: err.message
                })
              })
          }
        })
      })
  }

  /**
   * 跳回项目组
   */
  backProjectGroup () {
    this.$router.push('/project/my-project')
  }

  /**
   * 是否开通权限
   */
  get openRight () {
    const user = getterUserInfo()
    const openWebApi = user.webApiExpirationTime && moment(user.webApiExpirationTime).isAfter(moment.now())
    const openWebFront = user.webFrontExpirationTime && moment(user.webFrontExpirationTime).isAfter(moment.now())
    return openWebApi || openWebFront
  }

  /**
   * 当前用户的项目编辑权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canCreateProject
  }
}
