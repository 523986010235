import { Project } from '@/api/project/project'
import { showModal } from '@/libs/modal-helper'
import ProjectCreatorModal from './project-creator-modal.vue'

export default class Modals {
  static showProjectCreatorModal (data: Project, onOk?: (data: any) => Promise<void>) {
    showModal<any>(ProjectCreatorModal, {
      props: {
        dataProps: data
      }
    }, true, onOk, undefined)
  }
}
