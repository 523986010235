
import { BaseModal } from '@/libs/modal-helper'
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import ProjectBaseModal from './project-base-modal.vue'
import { Form } from 'view-design'
import { Project } from '@/api/project/project'
import _ from 'lodash'
import { getterUserInfo } from '@/store'
import moment from 'moment'

@Component({
  name: 'ProjectCreatorModal',
  components: { ProjectBaseModal }
})
export default class ProjectCreatorModal extends Vue implements BaseModal {
  @Prop({
    type: String,
    default: '新建项目'
  })
  title!: string;

  @Prop({
    type: Object,
    default: () => {
      return {
        type: 1
      }
    }
  })
  dataProps!: Project;

  data: Project = _.cloneDeep(this.dataProps);

  securityFrame = 'xbgSecurity';

  useJpa = true;

  useCloud = true;

  userInfo = getterUserInfo();

  /// 表单校验规则
  rules = {
    type: [
      { required: true, type: 'number', message: '必须选择项目类型', trigger: 'blur' }
    ],
    orgName: [
      { required: true, message: '组织名称不能为空', trigger: 'blur' },
      {
        pattern: '[a-zA-Z]+[0-9a-zA-Z_]*(\\.[a-zA-Z]+[0-9a-zA-Z_]*)*',
        message: '字母与.或者-的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    name: [
      { required: true, message: '项目唯一名不能为空', trigger: 'blur' },
      {
        pattern: '[a-zA-Z]+[0-9a-zA-Z_]*([a-zA-Z]+[0-9a-zA-Z_]*)*',
        message: '字母与.或者-的组合，且字母开始',
        trigger: 'blur'
      }
    ],
    title: [{ required: true, message: '项目标题不能为空', trigger: 'blur' }]
  };

  get canCreateWebApi () {
    const user = getterUserInfo()
    return user.webApiExpirationTime && moment(user.webApiExpirationTime).isAfter(moment.now())
  }

  get canCreateWebFront () {
    const user = getterUserInfo()
    return user.webFrontExpirationTime && moment(user.webFrontExpirationTime).isAfter(moment.now())
  }

  show (): void {
    (this.$refs.dlg as ProjectBaseModal).show()
  }

  close (): void {
    (this.$refs.dlg as ProjectBaseModal).close()
  }

  beforeChange () {
    return new Promise<void>((resolve) => {
      if (this.useJpa) {
        this.securityFrame = 'none'
      }
      resolve()
    })
  }

  doSave (): void {
    const form = this.$refs.form as Form
    this.data.options = JSON.stringify({
      useJpa: this.useJpa,
      useCloud: this.useCloud,
      securityFrame: this.securityFrame
    })
    form.validate((result) => {
      if (result) {
        this.$emit('onOk', this.data)
      } else {
        this.$Notice.error({
          title: '错误',
          desc: '数据检查失败!'
        })
      }
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }
}
