import { expect } from 'chai'

/**
 * 权限项描述
 */
export interface RightItemDescribe{
  /// 标题  以模块分级名加.名称
  title: string;
  /// 权限项的编号值 以模块编号.id
  code: string;
  /// 以id组成的code
  idcode: string;
}

/**
 * 权限项定义
 */
export interface RightItem{
    /// 权限项id
    id: number;
    /// 权限项名称
    name: string;
    /// 标题
    title: string;
    /// 是否控制菜单可见
    moduleView: boolean;
    /// 描述
    descript: string;
    /// 权限项的依赖项
    depends?: string[];
    /// 权限项的依赖组
    dependsGroups?: number[];
}

/**
 * 模块项定义
 */
export interface ModuleItem{
    /// 模块编码,3位一段
    code: string;
    /// 模块的标题
    title: string;
    /// 模块的名称
    name: string;
    /// 模块的路由路径
    path: string;
    /// 模块icon名称，对应fonticon
    icon: string;
    /// 是否是菜单项
    showOnMenu: boolean;
    /// 是否子页面
    subPage: boolean;
    /// 子菜单列表
    children: ModuleItem[];

    /// 模块下权限项列表
    rightItems: RightItem[];
    /// uuid
    id: number;
}

/**
 * 权限组
 */
export interface RightItemGroup{
  /// 名称
  name: string;
  /// 权限组编号
  id: number;
  /// 权限项成员
  items: string[];
}

export default class RightItemDefineManger {
  data: ModuleItem[]=[];
  /// 权限组名
  groups: RightItemGroup[]=[];
  /// 每一级编号长度
  private codelevelLen=3;

  /**
   * 从字串中加载权限数据
   * @param dataStr
   */
  public reload (dataStr: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      expect(dataStr, '不能加载空的数据').not.undefined
      try {
        const json = JSON.parse(dataStr)
        this.data = json.data
        this.groups = json.groups

        // 对原来空的数据做补全
        this.data.forEach((item) => this.repairData(item))

        resolve()
      } catch (ex) {
        reject(new Error('加载数据失败:' + ex))
      }
    })
  }

  /**
   * 修复缺少的数据
   * @param item
   */
  private repairData (item: ModuleItem) {
    if (!item.rightItems) item.rightItems = []
    if (!item.children) item.children = []
    for (const subItem of item.children) {
      this.repairData(subItem)
    }
  }

  /**
   * 更新所有的模块
   * @param items
   */
  public setModules (items: ModuleItem[]): void {
    this.data = items
  }

  /**
   * 清除数据
   */
  public clear (): void {
    this.data = []
    this.groups = []
  }

  /**
   * 在指定的位置添加模块
   * @param module
   * @param index
   * @param parent
   */
  public addModule (module: ModuleItem, index: number, parent?: ModuleItem): void {
    expect(module, '不能添加空的模块').not.undefined
    let parentNodes = this.data
    if (parent) {
      parentNodes = parent.children
    }
    // 在对应的位置插入数据
    parentNodes.splice(index, 0, module)
    // 更新这个父节点下的所有节点编号
    this.updateModuleCode(parentNodes, parent?.code)
  }

  /**
   * 更新模块及其所有子模块的编号
   * @param module
   */
  public updateModuleCode (modules?: ModuleItem[], parentCode?: string): void{
    const parentNodes = modules || this.data
    parentCode = parentCode || ''

    parentNodes.forEach((item, index) => {
      item.code = parentCode! + this.prefixInteger(index, this.codelevelLen)
      // 递归下一级节点
      this.updateModuleCode(item.children, item.code)
    })
  }

  // num传入的数字，n需要返回的字符长度
  private prefixInteger (num: number, len: number): string {
    return (Array(len).join('0') + num).slice(-len)
  }

  /**
   * 取得编号对应的模块链
   * @param code
   * @param deeplevel
   * @param parentNode
   */
  public getModuleLink (codes: string, deeplevel?: number, parentNode?: ModuleItem): ModuleItem[] {
    const parentNodes = parentNode ? parentNode.children : this.data
    if (!deeplevel) deeplevel = 0

    // 取得当前级的code
    const code = codes.substr(0, (deeplevel! + 1) * this.codelevelLen)

    const index = parentNodes.findIndex((item) => item.code === code)
    if (index !== -1) {
      if (code === codes) {
        return [parentNodes[index]]
      } else {
        return [parentNodes[index], ...this.getModuleLink(codes, deeplevel + 1, parentNodes[index])]
      }
    }
    return []
  }

  /**
   * 取得模块的父节点，如果是第一级，则返回undefined
   * @param code
   * @param deepLevel
   */
  public getModuleByCode (codes: string, deeplevel?: number, parentNode?: ModuleItem): ModuleItem|undefined {
    const parentNodes = parentNode ? parentNode.children : this.data
    if (!deeplevel) deeplevel = 0
    // 取得当前级的code
    const code = codes.substr(0, (deeplevel! + 1) * this.codelevelLen)

    const index = parentNodes.findIndex((item) => item.code === code)
    /// 查找到了则删除它
    if (index !== -1) {
      if (code === codes) {
        /// 如果正好在这一级列表中存在
        return parentNodes[index]
      } else {
        /// 否则向下级搜索
        return this.getModuleByCode(codes, deeplevel + 1, parentNodes[index])
      }
    }
    return undefined
  }

  /**
   * 通过模块的id层级编码，取得模块
   * @param codes
   */
  public getModuleByIdCode (codes: string): ModuleItem {
    const arr = codes.split('.')
    let nodes = this.data
    let module: ModuleItem

    for (let i = 0; i < arr.length; i++) {
      const index = nodes.findIndex((item) => item.id === parseInt(arr[i]))
      if (index !== -1) {
        module = nodes[index]
        nodes = module.children
      } else {
        console.error(`没有找到${codes}对应的模块`)
        break
      }
    }
    return module!
  }

  /**
   * 取得权限项，通过模块id+id的方式
   * @param codes
   */
  public getRightItemByCode (codes: string) {
    let index = codes.lastIndexOf('.')
    const moduleIdCodes = codes.substr(0, index)
    const rightId = parseInt(codes.substr(index + 1))
    const module = this.getModuleByIdCode(moduleIdCodes)
    index = module.rightItems.findIndex((item) => item.id === rightId)
    return module.children[index]
  }

  /**
   * 在指定的编号的子中添加一个模块
   * @param module
   * @param preItemCode
   */
  public insertModule (module: ModuleItem, preItemCode?: string): void {
    // 取得前一个节点的模块位置
    const parentNode = preItemCode ? this.getModuleByCode(preItemCode!) : undefined
    const nodes = parentNode?.children || this.data

    nodes.push(module)
    this.updateModuleCode(nodes, preItemCode || '')
  }

  /**
   * 通过编号删除模块列表
   * @param codes
   */
  public removeModuleByCodes (codes: string[]): void{
    let pre: string|undefined
    for (const code of codes.sort()) {
      if (pre && code.startsWith(pre)) {
        // 如果要删除的编号是上一个编号的子，则直接跳过
        continue
      }
      pre = code
      this.removeModuleByCode(code, 0, undefined)
    }

    // 对模块的序号重排
    const arry: string[] = []
    pre = undefined
    codes.map((item) => item.substr(0, item.length - this.codelevelLen)).sort()
      .forEach((item) => {
        if (item !== pre && !item.startsWith(pre || '----')) {
          arry.push(item)
          pre = item
        }
      })
    // 对指定的编号重排
    for (const code of arry) {
      this.updateModuleCode(code === '' ? this.data : this.getModuleByCode(code)?.children, code)
    }
  }

  /**
   * 通过编号删除模块
   * @param code 要删除的模块的分段之后的编号列表
   */
  public removeModuleByCode (codes: string, deeplevel?: number, parentNodes?: ModuleItem[]): void{
    if (!parentNodes) {
      parentNodes = this.data
    }
    if (!deeplevel) deeplevel = 0
    // 取得当前级的code
    const code = codes.substr(0, (deeplevel + 1) * this.codelevelLen)

    const index = parentNodes.findIndex((item) => item.code === code)

    /// 查找到了则删除它
    if (index !== -1) {
      if (code === codes) {
        /// 如果正好在这一级列表中存在要删除的节点，则完成删除
        parentNodes.splice(index, 1)
        this.updateModuleCode(parentNodes, code.substr(0, (deeplevel) * this.codelevelLen))
      } else {
        /// 否则向下级搜索
        this.removeModuleByCode(codes, deeplevel + 1, parentNodes[index].children)
      }
    }
  }

  /// 检查所有的节点是不是有重复的节点
  public hasRightItemId (id: number, uuid?: number, module?: ModuleItem): boolean {
    /// 如果模块不为空，则检查它的权限项
    if (module) {
      for (const item of module.rightItems) {
        if (item.id === id) return true
      }
    }

    /// 再检查它下级的模块
    const arry = module ? module.children : this.data
    for (const ch of arry) {
      if (this.hasRightItemId(id, uuid, ch)) return true
    }

    return false
  }

  /**
   * 取得编号对应的模块分级全名
   * @param code
   * @returns
   */
  public getCodeFullName (code: string): string {
    if (!code || code === '') return ''

    const list = this.getModuleLink(code)
    return list.map((item) => item.title).join('/')
  }

  /**
   * 数据转为字串
   * @returns
   */
  public toString (): string {
    return JSON.stringify({ data: this.data, groups: this.groups })
  }
}
