// 项目类型定义
export const ProjectTypeWebApi = 1
export const ProjectTypeWebFront = 2
export const ProjectTypeFlutterApp = 3

/**
 * 项目组相关基础信息
 */
export interface Project {
    /// ids
    id?: number;
    /// 项目唯一名
    name?: string;
    /// 项目全名
    title?: string;
    /// 组id
    projectGroupId?: number;
    /// 项目类型
    type?: number;
    /// tuandui id
    teamId?: number;
    /// git地址
    gitUrl?: string;
    /// git账号
    gitUser?: string;
    /// git密码
    gitPwd?: string;
    // 语言(CN中文,EN英文 用逗号分隔)
    language?: string;
    // 是否删除
    deleted?: boolean;
    /// 项目配置
    options?: string;
}
